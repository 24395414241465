import { useFields } from 'link-redux';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import React, { useState } from 'react';

import { postalCodeIri } from '../lib/helpers';
import { tryParseFloat } from '../lib/numbers';
import app from '../../ontology/app';
import { Priorities } from '../components/Map/GlappMap';
import teamGL from '../../ontology/teamGL';

import { fetchJson } from './useJSON';

const DEFAULT_MAX_PRIORITY = 5;
const DEFAULT_MIN_PRIORITY = 1;
const SHADE_COUNT = 10;

const hoverShapeStyle = new Style({
  fill: new Fill({
    color: 'rgba(117,117,117,0.22)',
  }),
  stroke: new Stroke({
    color: '#323232',
    width: 1,
  }),
});

export interface PostalMapping {
  [postalDigits: string]: Feature[];
}

interface ShapeStyle {
  [name: string]: Style;
}

const shapeStyle: ShapeStyle = {};

for (let i = 0; i <= SHADE_COUNT; i++) {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  const fillColor = `hsla(120, 64%, ${(65 - (5 * i)).toFixed()}%, ${(0.2 + 0.06 * i).toFixed(2)})`;
  /* eslint-enable @typescript-eslint/no-magic-numbers */

  shapeStyle[i] = new Style({
    fill: new Fill({
      color: fillColor,
    }),
  });
}

const styleForPostalCode = (
  feature: Feature,
  maxPriority: number,
  minPriority: number,
  pc4Priorities: Priorities,
  pc5Priorities: Priorities,
) => {
  const { postcode5, postalDigits } = feature.getProperties();
  const priority = pc4Priorities[postalCodeIri(postalDigits).value] ?? pc5Priorities[postcode5];
  const range = maxPriority - minPriority;
  const prioIndex = (
    SHADE_COUNT * (Math.max(0, (Math.min(priority, maxPriority) - minPriority)) / range)
  ).toFixed();

  return shapeStyle[prioIndex];
};

const useStyleForPostalCode = (pc4Priorities?: Priorities, pc5Priorities?: Priorities) => {
  const [maxPriorityProp] = useFields(app.c_a, teamGL.maxPriority);
  const maxPriority = tryParseFloat(maxPriorityProp);
  const [minPriorityProp] = useFields(app.c_a, teamGL.minPriority);
  const minPriority = tryParseFloat(minPriorityProp);

  const getStyle = React.useCallback((feature: Feature) => (
    pc4Priorities && pc5Priorities && styleForPostalCode(
      feature,
      maxPriority || DEFAULT_MAX_PRIORITY,
      minPriority || DEFAULT_MIN_PRIORITY,
      pc4Priorities,
      pc5Priorities,
    )
  ), [maxPriority, minPriority, pc4Priorities, pc5Priorities]);

  return getStyle;
};

interface UsePostalShapes {
  pc4Priorities?: Priorities;
  pc5Priorities?: Priorities;
  shapeFile: string;
}

const usePostalShapes = ({
  pc4Priorities,
  pc5Priorities,
  shapeFile,
}: UsePostalShapes): [mapping: PostalMapping, loading: boolean] => {
  const getPostalCodeStyle = useStyleForPostalCode(pc4Priorities, pc5Priorities);
  const [postalShapes, setPostalShapes] = React.useState<PostalMapping>({});
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (pc4Priorities && pc5Priorities) {
      setLoading(true);
      fetchJson(shapeFile).then((json) => {
        const postalMapping: PostalMapping = {};
        const features = (new GeoJSON()).readFeatures(json);
        features.forEach((f) => {
          f.setProperties({
            hoverStyle: () => hoverShapeStyle,
            style: getPostalCodeStyle,
          });

          const { postcode5, postalDigits } = f.getProperties();
          const digits = postalDigits ?? postcode5.slice(0, -1);

          if (!postalMapping[digits]) {
            postalMapping[digits] = [];
          }

          postalMapping[digits].push(f);
        });

        setPostalShapes(postalMapping);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [shapeFile, !!pc4Priorities, !!pc5Priorities]);

  return [postalShapes, loading];
};

export default usePostalShapes;
