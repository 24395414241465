import {
  FormControlLabel,
  Switch,
  useMediaQuery, 
} from '@mui/material';
import {
  makeStyles,
  useTheme,
} from '@mui/styles';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import { useFields } from 'link-redux';
import React, {
  FC,
  useState,
} from 'react';
import { Helmet } from 'react-helmet-async';

import CardContent from '../../../components/Card/CardContent';
import CardError from '../../../components/Error/CardError';
import useFontsChecker from '../../../hooks/useFontsChecker';
import app from '../../../../ontology/app';
import { appConfig } from '../../../lib/config';
import Card from '../../../topologies/Card';
import { cardClassIdentifier } from '../../../topologies/Card/sharedCardStyles';
import { BreakPoints } from '../../../theme/types';
import useMapAccessToken from '../../../components/Map/hooks/useMapAccessToken';
import GlappMap from '../../../components/Map/GlappMap';
import emoji from '../../../lib/emoji';
import SearchPostalForm from '../../../components/SearchPostalForm';

const useStyles = makeStyles(() => ({
  wrapperFull: {
    [`& .${cardClassIdentifier}`]: {
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
    },
    '& p': {
      marginBottom: 0,
    },
    'left': '5vw',
    'paddingRight': '5vw',
    'position': 'absolute',
    'top': '5vw',
    'zIndex': 900,
  },
  wrapperSmall: {
    [`& > .${cardClassIdentifier}`]: {
      '& p': {
        marginBottom: 0,
      },
      'boxShadow': '0 2px 5px rgba(0, 0, 0, 0.5)',
      'margin': 0,
    },
    'left': '1em',
    'maxWidth': '100%',
    'position': 'absolute',
    'right': '1em',
    'top': '1em',
    'zIndex': 900,
  },
}));

const HomePage: FC = () => {
  const fontLoaded = useFontsChecker('normal 18px FontAwesome');
  const classes = useStyles();
  const theme: any = useTheme();

  const screenIsWide = useMediaQuery(theme.breakpoints.up(BreakPoints.Medium));
  const [name] = useFields(app.c_a, schema.givenName);
  const [detailedMap, setDetailedMap] = useState(false);

  const [selectedPostalCode, setSelectedPostalCodeRaw] = React.useState<number | undefined>(undefined);
  const setSelectedPostalCode = React.useCallback((postalCode?: number) => {
    setSelectedPostalCodeRaw(postalCode);
  }, [setSelectedPostalCodeRaw]);
  const [mapToken, requestMapToken] = useMapAccessToken();

  if (mapToken.error) {
    return (
      <Card>
        <CardContent endSpacing>
          <CardError
            caughtError={mapToken.error}
            reloadLinkedObject={requestMapToken}
          />
        </CardContent>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {appConfig('appName')}
        </title>
      </Helmet>
      {fontLoaded && !mapToken.loading && (
        <GlappMap
          detailedMap={detailedMap}
          selectedPostalCode={selectedPostalCode}
          setSelectedPostalCode={setSelectedPostalCode}
        />
      )}
      <div className={screenIsWide ? classes.wrapperFull : classes.wrapperSmall}>
        <Card>
          <CardContent>
            <h2>
              {emoji(name ? t('home.greeting.withName', { name: name.value }) : t('home.greeting.withoutName'))}
            </h2>
          </CardContent>
          <CardContent endSpacing>
            <div>
              {t('home.introMessage')}
            </div>
            <SearchPostalForm
              setSelectedPostalCode={setSelectedPostalCode}
            >
              <FormControlLabel
                control={(
                  <Switch
                    checked={detailedMap}
                    color="secondary"
                    onChange={(_e, value) => {
                      setDetailedMap(value);
                    }}
                  />
                )}
                label={t('home.moreDetail')}
              />
            </SearchPostalForm>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
