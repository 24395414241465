import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import {
  SubjectProp,
  useNumbers,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';
import {
  TabContext,
  TabPanel,
} from '@mui/lab';

import AttributeListItem from '../../../components/AttributeListItem';
import CardContent from '../../../components/Card/CardContent';
import Heading from '../../../components/Heading';
import AttributeList from '../../../topologies/AttributeList';
import CardMain from '../../../topologies/Card/CardMain';
import Container from '../../../topologies/Container';
import teamGL from '../../../../ontology/teamGL';
import TabBar from '../../../components/TabBar';
import useTabBar from '../../../components/TabBar/lib/useTabBar';
import HeadingContext from '../../../components/Heading/HeadingContext';

import PostalCodeStreetsTab from './tabs/PostalCodeStreetsTab';
import PostalCodeActionsTab from './tabs/PostalCodeActionsTab';

const PostalCodeShow: FC<SubjectProp> = ({ subject }) => {
  const [name] = useStrings(subject, schema.name);
  const [doors] = useNumbers(subject, teamGL.doors);
  const [volunteers] = useNumbers(subject, teamGL.volunteers);
  const [active] = useNumbers(subject, teamGL.active);
  const [currentTab, handleTabChange] = useTabBar('street');

  return (
    <TabContext value={currentTab}>
      <Container>
        <CardMain>
          <CardContent noSpacing>
            <Heading>
              {name}
            </Heading>
            <p>
              {t('postcode.description')}
            </p>
            <AttributeList fullLabel>
              <AttributeListItem label={t('postcode.table.doors')}>
                {doors}
              </AttributeListItem>
              <AttributeListItem label={t('postcode.table.volunteers')}>
                {volunteers}
              </AttributeListItem>
              <AttributeListItem label={t('postcode.table.active')}>
                {active}
              </AttributeListItem>
            </AttributeList>
          </CardContent>
          <TabBar
            handleTabChange={handleTabChange}
            tabs={[{
              label: t('postcode.tabs.streets'),
              value: 'street', 
            }, {
              label: t('postcode.tabs.events'),
              value: 'event',
            }]}
          />
        </CardMain>
      </Container>
      <HeadingContext>
        <TabPanel value="street">
          <PostalCodeStreetsTab subject={subject} />
        </TabPanel>
        <TabPanel value="event">
          <PostalCodeActionsTab subject={subject} />
        </TabPanel>
      </HeadingContext>
    </TabContext>
  );
};

export default PostalCodeShow;
