import {
  SubjectProp,
  useNumbers,
} from 'link-redux';
import React, { FC } from 'react';

import teamGL from '../../../../../ontology/teamGL';
import CollectionProvider from '../../../../components/Collection/CollectionProvider';
import EventCard from '../../../../models/events/EventCard';
import app from '../../../../../ontology/app';
import Container from '../../../../topologies/Container';
import { Size } from '../../../../theme/types';

const PostalCodeStreetsTab: FC<SubjectProp> = ({ subject }) => {
  const [postalDigits] = useNumbers(subject, teamGL.postalDigits);
  const collectionIRI = app.ns(`postal_codes/${postalDigits}/events?sort%5B%5D=http%253A%252F%252Fglapp.nl%252Ftgl%2523distance%3Dasc&sort%5B%5D=http%253A%252F%252Fglapp.nl%252FstartDate%3Dasc`);

  return (
    <Container size={Size.Large}>
      <CollectionProvider
        renderWhenEmpty
        renderer={EventCard}
        subject={collectionIRI}
      />
    </Container>
  );
};

export default PostalCodeStreetsTab;
