import IconButton from '@mui/material/IconButton';
import * as rdfs from '@ontologies/rdfs';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import {
  SubjectProp,
  useGlobalIds,
  useNumbers,
  useResourceProperty,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';
import FontAwesome from 'react-fontawesome';

import teamGL from '../../../ontology/teamGL';
import AttributeListItem from '../../components/AttributeListItem';
import Button from '../../components/Button';
import CardContent from '../../components/Card/CardContent';
import HeaderWithFloat from '../../components/HeaderWithFloat';
import HeadingContext from '../../components/Heading/HeadingContext';
import { LoadingCard } from '../../components/Loading';
import DepartmentDetail from '../../details/DepartmentDetail';
import PriorityDetail from '../../details/PriorityDetail';
import useIsLoading from '../../hooks/useIsLoading';
import { useVisitPostalCode } from '../../lib/helpers';
import AttributeList from '../../topologies/AttributeList';
import Card from '../../topologies/Card';
import ContentDetails from '../../topologies/ContentDetails';

interface PostalCodeDialogProps extends SubjectProp {
  onClose: () => void;
}

const PostalCodeOverlay: FC<PostalCodeDialogProps> = ({ onClose, subject }) => {
  const { visitPostalCode } = useVisitPostalCode();
  const [postalDigits] = useNumbers(subject, teamGL.postalDigits);
  const [doors] = useNumbers(subject, teamGL.doors);
  const [volunteers] = useNumbers(subject, teamGL.volunteers);
  const [active] = useNumbers(subject, teamGL.active);
  const [title] = useStrings(subject, [schema.name, rdfs.label]);
  const [department] = useGlobalIds(subject, teamGL.department);
  const [meanPriority] = useResourceProperty(subject, teamGL.meanPriority);
  const loading = useIsLoading(subject, department);

  if (loading) {
    return (
      <LoadingCard />
    );
  }

  const closeButton = (
    <IconButton
      size="small"
      title={t('form.close')}
      type="button"
      onClick={onClose}
    >
      <FontAwesome name="close" />
    </IconButton>
  );

  return (
    <HeadingContext>
      <Card>
        <CardContent endSpacing>
          <HeaderWithFloat
            float={closeButton}
            title={title}
          />
          <ContentDetails>
            <DepartmentDetail department={department} />
          </ContentDetails>
          <PriorityDetail priority={meanPriority} />
          <AttributeList fullLabel>
            <AttributeListItem label={t('postcode.table.doors')}>
              {doors}
            </AttributeListItem>
            <AttributeListItem label={t('postcode.table.volunteers')}>
              {volunteers}
            </AttributeListItem>
            <AttributeListItem label={t('postcode.table.active')}>
              {active}
            </AttributeListItem>
          </AttributeList>
          <Button
            centered
            onClick={() => visitPostalCode(postalDigits)}
          >
            Aan de slag!
          </Button>
        </CardContent>
      </Card>
    </HeadingContext>
  );
};

export default PostalCodeOverlay;
