import { SomeNode } from 'link-lib';
import { TopologyContextType } from 'link-redux/dist-types/types';
import React, { FC } from 'react';

import SessionForm from '../../../components/Auth/SessionForm';

interface CreateSessionProps {
  subject: SomeNode;
  topologyCtx: TopologyContextType;
}

const SessionFormPage: FC<CreateSessionProps> = ({
  subject,
}) => (
  <SessionForm
    subject={subject}
  />
);

export default SessionFormPage;
