import rdf from '@ontologies/core';
import { useFields, useLRS } from 'link-redux';
import { Coordinate } from 'ol/coordinate';
import { fromLonLat, toLonLat } from 'ol/proj';
import React from 'react';
import { Box } from '@mui/material/index';
import Point from 'ol/geom/Point';

import { postalCodeIri } from '../../lib/helpers';
import useJSON from '../../hooks/useJSON';
import { tryParseFloat } from '../../lib/numbers';
import PostalCodeOverlay from '../../models/postalCodes/PostalCodeOverlay';
import { LoadingCard } from '../Loading';
import { ShowDialog } from '../../middleware/actions';
import app from '../../../ontology/app';
import teamGL from '../../../ontology/teamGL';
import useEventsLayer from '../../hooks/useEventsLayer';
import usePostalShapes from '../../hooks/usePostalShapes';
import useSelectedPostalCode from '../../hooks/useSelectedPostalCode';
import Spinner from '../Loading/Spinner';

import {
  ClusterSelectCallback,
  FeatureSelectCallback,
  MapVariant,
  MapViewChangeCallback,
  ViewProps,
} from './lib/types';
import { FOCUS_ZOOM } from './hooks/useMap';
import MapCanvas from './components/MapCanvas';
import useMapboxTileUrl from './hooks/useMapboxTileUrl';

const DEFAULT_LAT = 52.1344;
const DEFAULT_LON = 5.1917;
const DEFAULT_ZOOM = 6.8;
const DETAILED_POSTAL_LEVEL = 7;

const PC5_SHAPES = 'assets/postal_codes_pc5.json';
const DETAILED_SHAPES = 'assets/postal_codes.json';
const SIMPLIFIED_SHAPES = 'assets/postal_codes_simplified.json';

export interface Priorities {
  [state: string]: number;
}

export interface Event {
  image: string;
  iri: string;
  lat: number;
  lon: number;
}

export interface EventStats {
  events: Event[];
}

export interface PostalStats {
  pc4Priorities: Priorities;
  pc5Priorities: Priorities;
}

export interface GlappMapProps {
  detailedMap?: boolean;
  selectedPostalCode?: number;
  setSelectedPostalCode: (postalDigits?: number) => void;
}

const postalJSON = (detailedMap: boolean | undefined, zoom: number) => {
  if (detailedMap) {
    return PC5_SHAPES;
  }

  if (zoom > DETAILED_POSTAL_LEVEL) {
    return DETAILED_SHAPES;
  }

  return SIMPLIFIED_SHAPES;
};

const GlappMap: React.FC<GlappMapProps> = ({
  detailedMap,
  selectedPostalCode,
  setSelectedPostalCode,
}) => {
  const lrs = useLRS();
  const [zoomProp] = useFields(app.c_a, teamGL.zoom);
  const zoom = tryParseFloat(zoomProp);
  const [latProp] = useFields(app.c_a, teamGL.centerLat);
  const centerLat = tryParseFloat(latProp);
  const [lonProp] = useFields(app.c_a, teamGL.centerLon);
  const centerLon = tryParseFloat(lonProp);
  const [view, setView] = React.useState<ViewProps>({
    center: fromLonLat([centerLon ?? DEFAULT_LON, centerLat ?? DEFAULT_LAT]),
    zoom: zoom ?? DEFAULT_ZOOM,
  });
  const shapeFile = postalJSON(detailedMap, view?.zoom ?? 0);
  const [postalStats, loadingStats] = useJSON<PostalStats>('postal_stats.json');
  const [eventStats, loadingEvents] = useJSON<EventStats>('event_stats.json');
  const [postalShapes, loadingShapes] = usePostalShapes({
    pc4Priorities: postalStats?.pc4Priorities,
    pc5Priorities: postalStats?.pc5Priorities,
    shapeFile: shapeFile,
  });
  const [overlayPosition, setOverlayPosition] = React.useState<Coordinate | undefined>(undefined);
  const [selectedFeatures] = useSelectedPostalCode({
    postalShapes,
    selectedPostalCode,
    setOverlayPosition,
    setView,
    view,
  });
  const eventsLayer = useEventsLayer(eventStats?.events ?? []);
  const layers = React.useMemo(() => ([
    { features: Object.values(postalShapes).flat() },
    { features: selectedFeatures },
    eventsLayer,
  ]), [postalShapes, eventsLayer, selectedFeatures]);
  const mapboxTileURL = useMapboxTileUrl();
  const handleClusterSelect = React.useCallback<ClusterSelectCallback>((features, newCenter) => {
    const [lon, lat] = toLonLat((features[0].getGeometry() as Point).getCoordinates());
    lrs.actions.get(ShowDialog)(rdf.namedNode(`future_events?filter%5B%5D=http%253A%252F%252Fschema.org%252Flongitude%3D${lon}&filter%5B%5D=http%253A%252F%252Fschema.org%252Flatitude%3D${lat}`));
    setSelectedPostalCode(undefined);
    setOverlayPosition(newCenter);
  }, [lrs, setSelectedPostalCode, setOverlayPosition]);
  const handleSelect = React.useCallback<FeatureSelectCallback>((feature, newCenter) => {
    const { postcode5, postalDigits } = feature?.getProperties() || {};

    if (postalDigits) {
      setSelectedPostalCode(postalDigits);
    } else if (postcode5) {
      setSelectedPostalCode(parseInt(postcode5.slice(0, -1)));
    } else {
      const iri = feature?.getId() ? rdf.namedNode(feature.getId()) : null;

      if (iri) {
        lrs.actions.get(ShowDialog)(iri);
      }

      if (newCenter) {
        setOverlayPosition(newCenter);
        setView({
          center: newCenter,
          zoom: Math.max(view?.zoom || 0, feature ? FOCUS_ZOOM : 0),
        });
      }

      setSelectedPostalCode(undefined);
    }
  }, [setSelectedPostalCode, view.zoom]);
  const handleViewChange = React.useCallback<MapViewChangeCallback>((newCenter, newZoom) => {
    setView({
      center: newCenter,
      zoom: newZoom,
    });
  }, [setView]);

  const loading = loadingShapes || loadingStats || loadingEvents;

  const overlay = selectedPostalCode ? (
    <PostalCodeOverlay
      subject={postalCodeIri(selectedPostalCode.toString())}
      onClose={() => handleSelect(undefined)}
    />
  ) : undefined;

  if (!mapboxTileURL) {
    return <LoadingCard />;
  }

  return (
    <div>
      <MapCanvas
        layers={layers}
        mapboxTileURL={mapboxTileURL}
        overlay={overlay}
        overlayPosition={overlayPosition}
        variant={MapVariant.Fullscreen}
        view={view}
        onClusterSelect={handleClusterSelect}
        onSelect={handleSelect}
        onViewChange={handleViewChange}
      />
      {loading && (
        <Box
          overflow="hidden"
          position="absolute"
          right={0}
          top={0}
          zIndex={999}
        >
          <Spinner />
        </Box>
      )}
    </div>
  );
};

export default GlappMap;
