import * as rdfs from '@ontologies/rdfs';
import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import teamGL from '../../../../ontology/teamGL';
import CollectionProvider from '../../../components/Collection/CollectionProvider';
import LinkedDropdownMenu from '../../../components/DropdownMenu/LinkedDropdownMenu';
import EventStreetTable from '../../../models/eventStreets/EventStreetTable';
import ActionsBar from '../../../topologies/ActionsBar';
import CardContent from '../../../components/Card/CardContent';
import CardMainHeader from '../../../components/Card/CardMainHeader';
import Markdown from '../../../components/Markdown';
import CardMain from '../../../topologies/Card/CardMain';
import Container from '../../../topologies/Container';
import ontola from '../../../../ontology/ontola';
import EventDetails from '../../../models/events/EventDetails';
import EventSignUpButton from '../../../models/events/EventSignUpButton';

const EventShow: FC<SubjectProp> = ({ subject }) => {
  const [title] = useStrings(subject, [schema.name, rdfs.label]);
  const [text] = useStrings(subject, schema.text);
  const [signUpAction] = useGlobalIds(subject, ontola.signUpAction);
  const [eventStreets] = useGlobalIds(subject, teamGL.eventStreets);

  return (
    <Container>
      <CardMain>
        <CardContent>
          <CardMainHeader
            float={(
              <LinkedDropdownMenu
                label={ontola.actionsMenu}
                subject={subject}
              />
            )}
            title={title}
          />
          <EventDetails event={subject} />
          <Markdown text={text} />
        </CardContent>
        <ActionsBar>
          <EventSignUpButton subject={signUpAction} />
        </ActionsBar>
      </CardMain>
      <CollectionProvider subject={eventStreets}>
        <EventStreetTable />
      </CollectionProvider>
    </Container>
  );
};

export default EventShow;
