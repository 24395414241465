
import React, { FC } from 'react';
import {
  Route,
  Routes,
} from 'react-router';

import RouteNotFound from './components/Routes/RouteNotFound';
import WrapRoute from './components/Routes/WrapRoute';
import EventCard from './models/events/EventCard';
import CollectionShow from './pages/admin/collections/CollectionShow';
import AddressShow from './pages/main/addresses/AddressShow';
import ConfirmationShow from './pages/main/auth/ConfirmationShow';
import EventShow from './pages/main/events/EventShow';
import FormShow from './pages/main/forms/FormShow';
import SessionForm from './pages/main/forms/SessionForm';
import PageShow from './pages/main/pages/PageShow';
import PostalCodeShow from './pages/main/postalCodes/PostalCodeShow';
import EventsPage from './pages/main/static/EventsPage';
import HomePage from './pages/main/static/HomePage';
import ImpactPage from './pages/main/static/ImpactPage';
import StartPage from './pages/main/static/StartPage';
import StreetShow from './pages/main/streets/StreetShow';

const MainRoutes: FC = () => (
  <Routes>
    <Route
      element={<HomePage />}
      path="/"
    />
    <Route
      element={<StartPage />}
      path="/static/start"
    />
    <Route
      element={<EventsPage />}
      path="/static/events"
    />
    <Route
      element={<ImpactPage />}
      path="/static/impact"
    />
    <Route
      element={<WrapRoute component={AddressShow} />}
      path="/addresses/:id"
    />
    <Route
      element={<WrapRoute component={SessionForm} />}
      path="/u/session/new"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/u/registration/new"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/u/password/new"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/u/password/edit"
    />
    <Route
      element={<WrapRoute component={ConfirmationShow} />}
      path="/u/confirmation"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/u/confirmation/new"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/u/otp_attempt/new"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/u/otp_secret/new"
    />
    <Route
      element={<WrapRoute component={PageShow} />}
      path="/pages/:id"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/participants/:id/delete"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/potential_participants/:id/delete"
    />
    <Route
      element={<WrapRoute component={PostalCodeShow} />}
      path="/postal_codes/:id"
    />
    <Route
      element={<WrapRoute component={StreetShow} />}
      path="/streets/:id"
    />
    <Route
      element={(
        <WrapRoute
          component={CollectionShow}
          elementProps={{ renderer: EventCard }}
        />
      )}
      path="/events"
    />
    <Route
      element={<WrapRoute component={EventShow} />}
      path="/events/:id"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/events/:id/sign_up"
    />
    <Route
      element={<WrapRoute component={FormShow} />}
      path="/departments/new"
    />
    <Route
      element={<RouteNotFound />}
      key="linkedResources"
      path="*"
    />
  </Routes>
);

export default MainRoutes;
