import { NamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useGlobalIds,
  useIds,
  useLRS,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import ontola from '../../../../ontology/ontola';
import CardContent from '../../../components/Card/CardContent';
import EntryPointCardMain from '../../../components/EntryPoint/EntryPointCardMain';
import Heading from '../../../components/Heading';
import {
  HideDialog,
  Navigate,
} from '../../../middleware/actions';
import teamGL from '../../../../ontology/teamGL';
import CardMain from '../../../topologies/Card/CardMain';
import Container from '../../../topologies/Container';
import LinkLoader from '../../../components/LinkLoader';

const AddressShow: FC<SubjectProp> = ({ subject }) => {
  const lrs = useLRS();
  const [street] = useIds(subject, teamGL.street);
  const [name] = useStrings(subject, schema.name);
  const [createAction] = useIds(subject, ontola.createAction);
  const [target] = useGlobalIds(createAction, schema.target);
  const goToStreet = React.useCallback(() => {
    lrs.actions.get(Navigate)(street as NamedNode);
  }, [lrs]);

  if (!target) {
    return <LinkLoader />;
  }

  return (
    <Container>
      <CardMain>
        <CardContent endSpacing>
          <Heading>
            {name}
          </Heading>
          <EntryPointCardMain
            subject={target}
            onCancel={goToStreet}
            onDone={() => {
              lrs.actions.get(HideDialog);
              goToStreet();
            }}
          />
        </CardContent>
      </CardMain>
    </Container>
  );
};

export default AddressShow;
