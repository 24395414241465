import React, { FC } from 'react';

import CollectionProvider from '../../../../components/Collection/CollectionProvider';
import EarnedBadgeGrid from '../../../../models/badges/EarnedBadgeGrid';
import app from '../../../../../ontology/app';
import Container from '../../../../topologies/Container';
import { Size } from '../../../../theme/types';

const ImpactBadgesTab: FC = () => {
  const collectionIRI = app.ns('earned_badges');

  return (
    <Container size={Size.Large}>
      <CollectionProvider
        renderWhenEmpty
        renderer={EarnedBadgeGrid}
        subject={collectionIRI}
      />
    </Container>
  );
};

export default ImpactBadgesTab;
