import { t } from 'i18next';
import React, { FC } from 'react';

import Markdown from '../../../components/Markdown';
import Document from '../../../components/Document';
import SearchPostalForm from '../../../components/SearchPostalForm';

const StartPage: FC = () => (
  <Document title={t('pages.start.label')}>
    <Markdown text={t('pages.start.description')} />
    <SearchPostalForm />
  </Document>
);

export default StartPage;
