import { t } from 'i18next';
import React, { FC } from 'react';

import { useCurrentActor } from '../../../hooks/useCurrentActor';
import CollectionProvider from '../../../components/Collection/CollectionProvider';
import EventCard from '../../../models/events/EventCard';
import app from '../../../../ontology/app';
import Container from '../../../topologies/Container';
import PageHeader from '../../../topologies/PageHeader';
import PageHeaderCard from '../../../topologies/PageHeader/PageHeaderCard';
import { Size } from '../../../theme/types';
import { appName } from '../../../lib/config';

import glapp from './images/events/glapp.png';

const covers = {
  glapp,
} as Record<string, string>;

const getCollectionIRI = (postalDigits?: number) => {
  if (postalDigits) {
    return app.ns(`postal_codes/${postalDigits}/events?sort%5B%5D=http%253A%252F%252Fglapp.nl%252Ftgl%2523distance%3Dasc&sort%5B%5D=http%253A%252F%252Fglapp.nl%252FstartDate%3Dasc`);
  }
 
  return app.ns('future_events');
};

const EventsPage: FC = () => {
  const  { postalDigits } = useCurrentActor();

  const CollectionIRI = getCollectionIRI(postalDigits);

  return (
    <React.Fragment>
      <PageHeader
        background={covers[appName]}
        positionY={20}
      >
        <PageHeaderCard
          text={t('pages.events.body')!}
          title={t('pages.events.label')!}
        />
      </PageHeader>
      <Container size={Size.Large}>
        <CollectionProvider
          renderer={EventCard}
          subject={CollectionIRI}
        />
      </Container>
    </React.Fragment>
  );
};

export default EventsPage;
