import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useIds,
  useLRS,
  useNumbers,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

import CollectionProvider from '../../../components/Collection/CollectionProvider';
import CardContent from '../../../components/Card/CardContent';
import Heading from '../../../components/Heading';
import { entityIsLoaded } from '../../../lib/data';
import CardMain from '../../../topologies/Card/CardMain';
import Container from '../../../topologies/Container';
import app from '../../../../ontology/app';
import teamGL from '../../../../ontology/teamGL';
import AddressTable from '../../../models/addresses/AddressTable';

const StreetShow: FC<SubjectProp> = ({ subject }) => {
  const lrs = useLRS();
  const [name] = useStrings(subject, schema.name);
  const postalDigits = useNumbers(subject, teamGL.postalDigits);
  const [collectionIRI] = useIds(subject, teamGL.pendingAddresses);
  const preloadIris = [
    app.ns('enums/visits/result#neutral'),
    app.ns('enums/visits/spoke_somebody'),
    app.ns(`forms/survey_instances?postal_digits=${postalDigits}`),
    app.ns(`forms/visits?postal_digits=${postalDigits}`),
  ];

  preloadIris.forEach((iri) => {
    if (__CLIENT__ && !entityIsLoaded(lrs, iri)) {
      lrs.queueEntity(iri);
    }
  });

  return (
    <Container>
      <CardMain>
        <CardContent noSpacing>
          <Heading>
            {name}
          </Heading>
          <p>
            <span>
              {'Postcodegebied: '}
              {postalDigits.map((postalDigit) => (
                <Box
                  key={postalDigit}
                  sx={{
                    display:'inline-block',
                    marginRight: '0.3em',
                  }}
                >
                  <Link to={`/postal_codes/${postalDigit}`}>
                    {postalDigit}
                  </Link>
                </Box>
              ))}
            </span>
          </p>
        </CardContent>
      </CardMain>
      <CollectionProvider
        renderWhenEmpty
        subject={collectionIRI}
      >
        <AddressTable />
      </CollectionProvider>
    </Container>
  );
};

export default StreetShow;
