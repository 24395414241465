import {
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as schema from '@ontologies/schema';
import {
  SubjectProp,
  useDates,
  useFields,
  useIds,
  useStrings,
} from 'link-redux';
import React, { FC } from 'react';

import teamGL from '../../../ontology/teamGL';
import Heading, { HeadingSize } from '../../components/Heading';
import Image from '../../components/Image';
import Markdown from '../../components/Markdown';
import useIsLoading from '../../hooks/useIsLoading';
import { BreakPoints } from '../../theme/types';

import LoadingImage from './images/loading.png';

const PENDING_BADGE_OPACITY = 0.2;

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: '1em!important',
  },
  headingSmall: {
    fontSize: '.9em!important',
  },
}));

const EarnedBadgeGrid: FC<SubjectProp> = ({ subject }) => {
  const [badge] = useIds(subject, teamGL.badge);
  const [dateCreated] = useDates(subject, schema.dateCreated);
  const [image] = useFields(badge, schema.image);
  const [name] = useStrings(badge, schema.name);
  const [description] = useStrings(badge, schema.description);

  const loading = useIsLoading(badge);
  const theme = useTheme();
  const screenIsNarrow = useMediaQuery(theme.breakpoints.down(BreakPoints.Medium));
  const classes = useStyles();

  if (loading) {
    return (
      <React.Fragment>
        <Heading
          className={screenIsNarrow ? classes.headingSmall : classes.heading}
          size={HeadingSize.LG}
        >
          &nbsp;
        </Heading>
        <Box
          component="img"
          src={LoadingImage}
          sx={{
            height: 'auto',
            marginBottom: '1em',
            maxWidth: '100%',
            width: '180px',
          }}
        />
      </React.Fragment>
    );
  }

  return (
    <div
      style={{
        opacity: dateCreated ? 1 : PENDING_BADGE_OPACITY,
        textAlign: 'center',
      }}
    >
      <Heading
        className={screenIsNarrow ? classes.headingSmall : classes.heading}
        size={HeadingSize.LG}
      >
        {name}
      </Heading>
      <Image
        linkedProp={image}
        style={{
          height: 'auto',
          marginBottom: '1em',
          maxWidth: '100%',
          width: '180px',
        }}
      />
      <Markdown text={description} />
    </div>
  );
};

export default EarnedBadgeGrid;
