import * as schema from '@ontologies/schema';
import { useStrings } from 'link-redux';
import React, { FC } from 'react';
import { useParams } from 'react-router';

import Document from '../../../components/Document';
import app from '../../../../ontology/app';

const PageShow: FC = () => {
  const { id } = useParams();
  const eventIRI = app.ns(`pages/${id}`);
  const [title] = useStrings(eventIRI, schema.name);
  const [text] = useStrings(eventIRI, schema.text);

  return (
    <Document
      content={text}
      title={title}
    />
  );
};

export default PageShow;
