
import React, { ReactNode } from 'react';

export interface AttributeListItemProps {
  label: string;
  children: ReactNode;
}

const AttributeListItem: React.FC<AttributeListItemProps> = ({
  label,
  children,
}) => (
  <tr>
    <th>
      <label>
        {label}
      </label>
    </th>
    <td>
      {children}
    </td>
  </tr>
);

export default AttributeListItem;
