import HttpStatus from 'http-status-codes';
import React from 'react';

import { handle } from '../lib/logging';

export const fetchJson = (path: string) => fetch(path, { headers: { Accept: 'application/json' } })
  .then((res) => {
    if (res.status === HttpStatus.OK) {
      return res.json();
    }

    return Promise.reject();
  })
  .catch((e) => {
    handle(e);
  });

const useJSON = <T>(path: string | undefined): [data: T | undefined, loading: boolean, retry: () => void] => {
  const [json, setJSON] = React.useState<T | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);

  const reload = React.useCallback(() => {
    if (path) {
      setLoading(true);
      fetchJson(path).then((body) => {
        setJSON(body);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [path]);

  React.useEffect(() => {
    reload();
  }, [path, reload]);

  return [json, loading, reload];
};

export default useJSON;
