import { useTheme } from '@mui/material';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import React from 'react';

import { getStyles } from '../components/Map/lib/helpers';
import { Layer } from '../components/Map/lib/types';
import { Event } from '../components/Map/GlappMap';

const useEventsLayer = (eventsData: Event[]): Layer => {
  const [eventsFeatures, setEventsFeatures] = React.useState<Feature[]>([]);
  const theme = useTheme();

  React.useEffect(() => {
    const newEvents: Feature[] = [];

    eventsData.forEach((event) => {
      const {
        image,
        iri,
        lat,
        lon,
      } = event;
      const {
        hoverStyle,
        style,
      } = getStyles(image, theme);
      const f = new Feature(new Point(fromLonLat([lon, lat])));
      f.setId(iri);
      f.setProperties({
        hoverStyle,
        style,
      });
      newEvents.push(f);
    });

    setEventsFeatures(newEvents);
  }, [eventsData.length]);

  return React.useMemo(() => ({
    clustered: true,
    features: eventsFeatures,
  }), [eventsFeatures]);
};

export default useEventsLayer;
