import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Box } from '@mui/material/index';

import { tryParseInt } from '../lib/numbers';
import { useVisitPostalCode } from '../lib/helpers';
import { LibroTheme } from '../theme/types';

import { MAX_POSTAL_DIGITS, MIN_POSTAL_DIGITS } from './FormInput/Input/PostalRangeInput';

const useStyles = makeStyles((theme: LibroTheme) => ({
  button: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
  },
  iconButton: {
    padding: 10,
  },
  input: {
    flex: 1,
    padding: '2px 10px',
  },
}));

const inputProps = {
  max: MAX_POSTAL_DIGITS,
  min: MIN_POSTAL_DIGITS,
  type: 'number',
};

interface SearchPostalFormProps {
  setSelectedPostalCode?: (digits: number) => void;
}

const SearchPostalForm: React.FC<SearchPostalFormProps> = ({
  children,
  setSelectedPostalCode,
}) => {
  const classes = useStyles();
  const [postalCode, setPostalCode] = React.useState<number | undefined>(undefined);
  const {
    recentPostalCodes,
    visitPostalCode,
  } = useVisitPostalCode();
  const ref = React.useRef<HTMLElement>();

  const handlePostalClick = setSelectedPostalCode || visitPostalCode;

  return (
    <React.Fragment>
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();

          if (postalCode && postalCode > MIN_POSTAL_DIGITS && postalCode < MAX_POSTAL_DIGITS) {
            ref.current?.blur();
            handlePostalClick(postalCode);
          }
        }}
      >
        <InputBase
          className={classes.input}
          inputProps={inputProps}
          inputRef={ref}
          name="postal-input"
          placeholder="1234"
          value={postalCode}
          onChange={(e) => {
            e.preventDefault();
            setPostalCode(tryParseInt(e.target.value));
          }}
        />
        <IconButton
          className={classes.iconButton}
          name="submit-search"
          type="submit"
        >
          <SearchIcon />
        </IconButton>
      </form>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <p>
          {recentPostalCodes.map<JSX.Element>((digits) => (
            <button
              className={classes.button}
              key={digits}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handlePostalClick(parseInt(digits));
              }}
            >
              {digits}
            </button>
          )).reduce<JSX.Element[]>((result, item, index) => ([
            ...result,
            <React.Fragment key={index}>
              {result.length > 0 ? ', ' : ''}
            </React.Fragment>,
            item,
          ]), [])}
        </p>
        {children}
      </Box>
    </React.Fragment>
  );
};

export default SearchPostalForm;
