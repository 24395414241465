import React, { FC } from 'react';

import './i18n';

import App from '../app/components/App/App';
import { Globals } from '../app/components/App/typescript';
import HeaderMain from '../app/components/Header/HeaderMain';
import MainRoutes from '../app/MainRoutes';

const Main: FC<{globals: Globals, seed: string}> = ({ globals, seed }) => {
  if (globals) {
    (global as any).GLOBALS = globals;
  }

  return (
    <App
      header={HeaderMain}
      seed={seed}
    >
      <MainRoutes />
    </App>
  );
};

export default Main;
 
