import { NamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import { SomeNode } from 'link-lib';
import { useLRS } from 'link-redux';
import React, { FC } from 'react';

import teamGL from '../../../ontology/teamGL';
import TableFrame, { Column } from '../../components/Collection/Frame/TableFrame';
import RelativeDate from '../../components/RelativeDate';
import { useCurrentActor } from '../../hooks/useCurrentActor';
import { formatNumber } from '../../lib/formatters';
import { tryParseInt } from '../../lib/numbers';
import {
  Navigate,
  StartSignIn,
} from '../../middleware/actions';
import PriorityDetail from '../../details/PriorityDetail';

const columns: Column[] = [
  {
    label: t('table.streets.columns.name'),
    property: schema.name,
  },
  {
    label: t('table.streets.columns.priority'),
    property: teamGL.priority,
    render: ({ term }) => <PriorityDetail priority={term} />,
  },
  {
    label: t('table.streets.columns.lastVisitedAt'),
    property: teamGL.lastVisitedAt,
    render: ({ term }) => <RelativeDate date={term} />,
  },
  {
    label: t('table.streets.columns.pendingAddressesCount'),
    property: teamGL.pendingAddressesCount,
    render: ({ term }) => formatNumber(tryParseInt(term.value)),
  },
];

const StreetTable: FC = () => {
  const lrs = useLRS();
  const { actorType } = useCurrentActor();

  const onRowClick = (resource: SomeNode) => {
    if (actorType === 'GuestUser') {
      lrs.actions.get(StartSignIn)(resource as NamedNode);
    } else {
      lrs.actions.get(Navigate)(resource as NamedNode);
    }
  };

  return (
    <TableFrame
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

export default StreetTable;
