import { NamedNode } from '@ontologies/core';
import * as schema from '@ontologies/schema';
import { t } from 'i18next';
import { SomeNode } from 'link-lib';
import { useLRS } from 'link-redux';
import React, { FC } from 'react';

import teamGL from '../../../ontology/teamGL';
import TableFrame, { Column } from '../../components/Collection/Frame/TableFrame';
import { useCurrentActor } from '../../hooks/useCurrentActor';
import {
  ShowDialog,
  StartSignIn,
} from '../../middleware/actions';

const columns: Column[] = [
  {
    label: t('table.addresses.columns.address'),
    property: schema.name,
    sortProperty: teamGL.number,
  },
];

const AddressTable: FC = () => {
  const lrs = useLRS();
  const { actorType } = useCurrentActor();

  const onRowClick = (subject: SomeNode) => {
    if (actorType === 'GuestUser') {
      lrs.actions.get(StartSignIn)(subject as NamedNode);
    } else {
      lrs.actions.get(ShowDialog)(subject);
    }
  };

  return (
    <TableFrame
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

export default AddressTable;
