import { NamedNode } from '@ontologies/core';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import app from '../../ontology/app';

import { retrievePath } from './iris';

const MAX_IN_STORAGE = 5;

export const postalCodeIri = (postalDigits: string | number): NamedNode => app.ns(`postal_codes/${postalDigits}`);

export type VisitPostalCode = (digits: string | number) => void;

export const useVisitPostalCode = (): {
  recentPostalCodes: string[];
  visitPostalCode: (digits: string | number) => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();
  const [recentPostalCodes, addRecentPostalCode] = React.useMemo<[string[], VisitPostalCode]>(() => {
    if (!__CLIENT__) {
      return [[], () => null];
    }

    const rawFromStorage = localStorage.getItem('recentPostalDigits');
    const current = rawFromStorage ? JSON.parse(rawFromStorage) : [];

    const add = (value: string | number) => {
      const normalizedValue = value.toString();
      const newValue = current.filter((v: string) => (v !== normalizedValue));
      newValue.unshift(normalizedValue);
      localStorage.setItem('recentPostalDigits', JSON.stringify(newValue.slice(0, MAX_IN_STORAGE)));
    };

    return [current, add];
  }, []);

  const hash = location.hash ?? '#';
  const visitPostalCode = React.useCallback<VisitPostalCode>((digits) => {
    if (digits) {
      addRecentPostalCode(digits);
      navigate(`${retrievePath(postalCodeIri(digits))}${hash}`);
    }
  }, []);

  return {
    recentPostalCodes,
    visitPostalCode,
  };
};
