import { Box } from '@mui/material/index';
import { t } from 'i18next';
import React, { FC } from 'react';

import { useCurrentActor } from '../../../../hooks/useCurrentActor';
import CardContent from '../../../../components/Card/CardContent';
import Heading, { HeadingSize } from '../../../../components/Heading';
import Markdown from '../../../../components/Markdown';
import Progress from '../../../../components/Progress';
import Card from '../../../../topologies/Card';
import Container from '../../../../topologies/Container';
import { Size } from '../../../../theme/types';

const ImpactOverviewTab: FC = () => {
  const {
    actorType,
    activeDaysCount,
    doorsCount,
    nextDoorsTarget,
  } = useCurrentActor();

  return (
    <Container size={Size.Large}>
      <Heading size={HeadingSize.SM}>
        {t('pages.impact.numberOfDoors')}
      </Heading>
      <Box sx={{ marginBottom: '4em' }}>
        <Progress
          progressLabel
          height="15px"
          max={nextDoorsTarget!}
          min={0}
          value={doorsCount!}
        />
      </Box>
      {actorType === 'GuestUser' ? (
        <Card>
          <CardContent>
            <Markdown text={t('pages.impact.signIn')} />
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent endSpacing>
            <Box
              sx={{
                listStyleType: 'initial',
              }}
            >
              <Markdown
                noSpacing
                text={t('pages.impact.stats', {
                  activeDaysCount: activeDaysCount,
                  doorsCount: doorsCount,
                })}
              />
            </Box>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default ImpactOverviewTab;
