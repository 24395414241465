import rdf from '@ontologies/core';
import { SubjectProp } from 'link-redux';
import React, { FC } from 'react';

import CollectionProvider from '../../../../components/Collection/CollectionProvider';
import Container from '../../../../topologies/Container';
import { Size } from '../../../../theme/types';
import StreetTable from '../../../../models/streets/StreetTable';

const PostalCodeStreetsTab: FC<SubjectProp> = ({ subject }) => {
  const collectionIRI = rdf.namedNode(`${subject.value}/streets`);

  return (
    <Container size={Size.Large}>
      <CollectionProvider
        renderWhenEmpty
        subject={collectionIRI}
      >
        <StreetTable />
      </CollectionProvider>
    </Container>
  );
};

export default PostalCodeStreetsTab;
