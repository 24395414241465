import {
  TabContext,
  TabPanel,
} from '@mui/lab';
import { t } from 'i18next';
import React, { FC } from 'react';

import HeadingContext from '../../../components/Heading/HeadingContext';
import CardMain from '../../../topologies/Card/CardMain';
import Container from '../../../topologies/Container';
import PageHeader from '../../../topologies/PageHeader';
import PageHeaderCard from '../../../topologies/PageHeader/PageHeaderCard';
import { Size } from '../../../theme/types';
import TabBar from '../../../components/TabBar';
import useTabBar from '../../../components/TabBar/lib/useTabBar';
import { appName } from '../../../lib/config';

import ImpactBadgesTab from './tabs/ImpactBadgesTab';
import ImpactOverviewTab from './tabs/ImpactOverviewTab';
import glapp from './images/impact/glapp.jpg';

const covers = {
  glapp: glapp,
} as Record<string, string>;

const ImpactPage: FC = () => {
  const [currentTab, handleTabChange] = useTabBar('impact_overview');

  return (
    <TabContext value={currentTab}>
      <PageHeader
        background={covers[appName]}
        positionY={45}
      >
        <PageHeaderCard
          text={t('pages.impact.body')}
          title={t('pages.impact.label')}
        />
      </PageHeader>
      <Container size={Size.Large}>
        <CardMain>
          <TabBar
            handleTabChange={handleTabChange}
            tabs={[{
              label: t('pages.impact.tabs.overview'),
              value: 'impact_overview',
            }, {
              label: t('pages.impact.tabs.badges'),
              value: 'earned_badges',
            }]}
          />
        </CardMain>
      </Container>
      <HeadingContext>
        <TabPanel value="impact_overview">
          <ImpactOverviewTab />
        </TabPanel>
        <TabPanel value="earned_badges">
          <ImpactBadgesTab />
        </TabPanel>
      </HeadingContext>
    </TabContext>
  );
};

export default ImpactPage;
