import { SomeTerm } from '@ontologies/core';
import React, { FC } from 'react';

import Progress from '../components/Progress';
import { tryParseInt } from '../lib/numbers';

interface PriorityProps {
  endSpacing?: boolean;
  priority: SomeTerm;
}

const PriorityDetail: FC<PriorityProps> = ({ endSpacing, priority }) => {
  const value = tryParseInt(priority);

  if (!value) {
    return null;
  }

  return (
    <Progress
      endSpacing={endSpacing}
      max={100}
      min={0}
      value={value}
    />
  );
};

export default PriorityDetail;
