import * as schema from '@ontologies/schema';
import { useGlobalIds } from 'link-redux';
import React, { FC } from 'react';
import { useLocation } from 'react-router';

import appSlashless from '../../../../ontology/appSlashless';
import ontola from '../../../../ontology/ontola';
import { useDoneHandler } from '../../../components/Action/lib/helpers';
import EntryPointCardMain from '../../../components/EntryPoint/EntryPointCardMain';
import LinkLoader from '../../../components/LinkLoader';
import useIsLoading from '../../../hooks/useIsLoading';

const ConfirmationShow: FC = () => {
  const location = useLocation();
  const confirmation = appSlashless.ns(location.pathname + location.search);
  const [action] = useGlobalIds(confirmation, ontola.updateAction);
  const loading = useIsLoading(action);
  const onDoneHandler = useDoneHandler(action);
  const [target] = useGlobalIds(action, schema.target);

  if (loading) {
    return <LinkLoader />;
  }

  return (
    <EntryPointCardMain
      autoSubmit
      subject={target}
      onDone={onDoneHandler}
    />
  );
};

export default ConfirmationShow;
